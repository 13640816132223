<template>
  <div ref="pageContainerRef" class="PageContainer">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const pageContainerRef = ref<HTMLElement>();

useScrollMemory(pageContainerRef);
</script>

<style scoped lang="scss">
.PageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  padding-top: 0px;
  overflow: hidden;
}
</style>
